import {Toast} from "vant/lib/";

var base = "api-bxt-user/bxt-user"
let API = {
    login: base + "/login",
    register: base + "/register",
    workTypeList: base + "/config/workTypeList",
    areaList: base + "/config/areaList",
    levelList: base + "/config/levelList",
    footList: base + "/config/footList",
    headList: base + "/config/headList",
    userType: base + "/config/userType",
    getRouteContent: base + "/config/getRouteContent",
    getVerificationCode: base + "/getVerificationCode",
    myMenu: base + "/config/myMenu",
    getDetailUserInfo: base + "/getDetailUserInfo",
    getSecretNo: base + "/getSecretNo",
    cancel: base + "/cancel",
    edit: base + "/editUser",
    completeWorker: base + "/completeWorker",

}
import req from "../request/request";

function getSecretNo(reqJson){
    req.request(API.getSecretNo, reqJson, {method: 'POST'}, )
        .then((res) => {
            if (res.data.code == 200) {
                window.location.href='tel:'+res.data.data
            }
        })
}

export default API