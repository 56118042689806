<template>
<!--  <van-dialog  title="请关注公众号" >-->
<!--    <van-image :src="require('@/img/wx_qr_code.jpg')"></van-image>-->
<!--  </van-dialog>-->
  <div>
    test !!!!
  </div>
</template>

<script>

import {onBeforeMount, onMounted, reactive, toRefs} from 'vue'
import HeaderEle from '../components/common/headerEle'
import API from "../api/User";
import req from "../request/request";
import router from "../router";
import {useRoute} from "vue-router";


export default {
  name: 'Test',

  setup(){

    onBeforeMount(
        ()=>{
          localStorage.clear();
          sessionStorage.clear();
        }
    );
    let methodsMap = {
      // 设置
      setting: () => {


      },
    }
    return {
      ...methodsMap,
    }
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {

  }
}
</style>
