<template>
  <div class="headImgs">
    <van-swipe class="headImgsVanSwipe" :autoplay="3000" lazy-render>
      <van-swipe-item v-for="item in headList" :key="item">
        <!--        <img :src="item.imgUrl"/>-->
        <van-image
            height="10rem"
            fit="fill"
            :src="item.imgUrl"
            @click="jump(item.route)"
        />
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>

import {onMounted, reactive, toRefs} from "vue";
import req from "../../request/request";
import API from "../../api/User";
import router from "../../router";

export default {
  name: 'HeaderEle',
  components: {},

  setup() {

    onMounted(() => {
      methodsMap.getHeadList()
    })

    let data = reactive({
      headList: [],
    });
    const methodsMap = {
      getHeadList: () => {
        req.request(API.headList, null, {method: 'GET'}).then((res) => {
          data.headList = res.data.data
        })
      },

      jump: (url) => {
        if (url) {
          if (url.startsWith("http")) {
            window.location.href = url
          } else {
            router.replace(url)
          }
        }

      }
    }

    return {
      ...toRefs(data),
      ...methodsMap,
    }
  }

}
</script>
<style lang="less" scpoed>
.headImgs {
  &VanSwipe {
    //height: 12rem;
    //width: 100%;
    //
    //.van-swipe-item img {
    //  height: 100%;
    //  width: 100%;
    //}
  }
}
</style>